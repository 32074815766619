import { fabric } from 'fabric-with-erasing'

/**
 * 荧光笔
 */
fabric.InkBrush = fabric.util.createClass(fabric.BaseBrush, {
  initialize: function (canvas, options) {
    this.canvas = canvas
    options || (options = {})
    this.callSuper('initialize', options)
  },

  // 绘制线条
  _render: function (point, context) {
    const radius = this.width / 2
    context.beginPath()
    context.arc(point.x, point.y, radius, 0, Math.PI * 2)
    context.fillStyle = this.color
    context.globalAlpha = 0.5
    context.fill()
  },

  // 在画布上绘制
  onMouseDown: function (pointer) {
    this.points = [{
      x: pointer.x,
      y: pointer.y
    }]
    this._setShadow()
  },

  onMouseMove: function (pointer) {
    if (this.points.length > 100) {
      this.points.shift()
    }
    this.points.push({
      x: pointer.x,
      y: pointer.y
    })
    this._setShadow()
    this.canvas.add(new fabric.Circle({
      radius: this.width / 2,
      fill: this.color,
      left: pointer.x,
      top: pointer.y,
      originX: 'center',
      originY: 'center'
    }))
    this.canvas.requestRenderAll()
  },

  onMouseUp: function (pointer) {
    const originalRenderOnAddRemove = this.canvas.renderOnAddRemove
    this.canvas.renderOnAddRemove = false
    this.canvas.renderOnAddRemove = originalRenderOnAddRemove
    this.canvas.requestRenderAll()
  },

  _setShadow: function () {
    const ctx = this.canvas.getContext()
    ctx.shadowColor = this.color
    ctx.shadowBlur = this.width * 2
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0
  }
})
