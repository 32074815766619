import { fabric } from 'fabric-with-erasing'

/**
 * 蜡笔
 */
fabric.util.getRandom = function (max, min) {
  min = min || 0
  return Math.random() * ((max || 1) - min) + min
}
fabric.util.clamp = function (n, max, min) {
  if (typeof min !== 'number') min = 0
  return n > max ? max : n < min ? min : n
}
fabric.Point.prototype.normalize = function (thickness) {
  if (thickness === null || undefined === thickness) {
    thickness = 1
  }
  const length = this.distanceFrom({
    x: 0,
    y: 0
  })
  if (length > 0) {
    this.x = this.x / length * thickness
    this.y = this.y / length * thickness
  }

  return this
}
fabric.CrayonBrush = fabric.util.createClass(fabric.BaseBrush, {

  color: '#000000',
  opacity: 0.6,
  width: 30,

  _baseWidth: 20,
  _inkAmount: 10,
  _latestStrokeLength: 0,
  _point: null,
  _sep: 5,
  _size: 0,
  chunks: [],

  initialize: function (canvas, opt) {
    opt = opt || {}
    this.chunks = []
    this.canvas = canvas
    this.width = opt.width || canvas.freeDrawingBrush.width
    this.color = opt.color || canvas.freeDrawingBrush.color
    this.opacity = opt.opacity || canvas.contextTop.globalAlpha
    this._point = new fabric.Point(0, 0)
  },

  changeColor: function (color) {
    this.color = color
  },

  changeOpacity: function (value) {
    this.opacity = value
  },

  onMouseDown: function (pointer) {
    this.canvas.contextTop.globalAlpha = this.opacity
    this._size = this.width / 2 + this._baseWidth
    this.set(pointer)
  },

  onMouseMove: function (pointer) {
    this.update(pointer)
    this.draw(this.canvas.contextTop)
  },

  onMouseUp: function (pointer) {
    const originalRenderOnAddRemove = this.canvas.renderOnAddRemove
    this.canvas.renderOnAddRemove = false
    const rects = []
    this.chunks.forEach(element => {
      const rect = new fabric.Rect({
        width: element.w,
        height: element.h,
        left: element.x,
        top: element.y,
        fill: this.color,
        opacity: 1,
        selectable: false
      })
      rects.push(rect)
    })
    const group = new fabric.Group(rects)
    this.canvas.add(group)
    this.canvas.renderOnAddRemove = originalRenderOnAddRemove
    this.canvas.requestRenderAll()
    this.chunks = []
  },

  set: function (p) {
    if (this._latest) {
      this._latest.setFromPoint(this._point)
    } else {
      this._latest = new fabric.Point(p.x, p.y)
    }
    fabric.Point.prototype.setFromPoint.call(this._point, p)
  },

  update: function (p) {
    this.set(p)
    this._latestStrokeLength = this._point.subtract(this._latest).distanceFrom({ x: 0, y: 0 })
  },

  draw: function (ctx) {
    let i, j, p, r, c, x, y, w, h
    const v = this._point.subtract(this._latest)
    const s = Math.ceil(this._size / 2)
    const stepNum = Math.floor(v.distanceFrom({ x: 0, y: 0 }) / s) + 1
    const dotSize = this._sep * fabric.util.clamp(this._inkAmount / this._latestStrokeLength * 3, 1, 0.5)
    const dotNum = Math.ceil(this._size * this._sep)
    const range = this._size / 2
    v.normalize(s)
    ctx.save()
    ctx.fillStyle = this.color
    ctx.beginPath()
    for (i = 0; i < dotNum / 2; i++) {
      for (j = 0; j < stepNum / 2; j++) {
        p = this._latest.add(v.multiply(j))
        r = fabric.util.getRandom(range)
        c = fabric.util.getRandom(Math.PI * 2)
        w = fabric.util.getRandom(dotSize, dotSize / 2)
        h = fabric.util.getRandom(dotSize, dotSize / 2)
        x = p.x + r * Math.sin(c) - w / 2
        y = p.y + r * Math.cos(c) - h / 2
        ctx.rect(x.toFixed(5), y.toFixed(5), w.toFixed(5), h.toFixed(5))
        this.chunks.push({ x, y, w, h })
      }
    }
    ctx.fill()
    ctx.restore()
  }
})
